import { Form, usePostMessageContext } from 'connex-cds';
import { useCallback, useMemo } from 'react';
import { useDataStore } from './datastore/DataStore';
import { useTicketContext } from './TicketContext';
import { Logger } from '../../../../util/log/logger';

const reason = {
  crn: 'autosubmit',
  id: 'autosubmit',
  description: 'Auto Submit',
};

export const useAutomaticTicketSubmission = () => {
  const { reset, setSubmitted } = useTicketContext();
  const { errors, setFieldValue } = Form.useFormContext();
  const { sendMessage } = usePostMessageContext();
  const { submitTicket } = useDataStore();

  const saveTicket = useCallback(
    async ticket => {
      const response = await submitTicket(ticket);
      Logger.debug(
        `Mobile Ticket -
        frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - saveTicket: ${JSON.stringify(
          response
        )}`
      );
      sendMessage(JSON.stringify({ type: 'submit', ticket, response }));
      return response;
    },
    [sendMessage, submitTicket]
  );

  const waterIndexes = useMemo(() => {
    return errors?.waterAddedEvents?.reduce((acc, el, pos) => (el !== null ? [...acc, pos] : acc), []);
  }, [errors]);

  const ticketSubmission = useCallback(
    async (keepTicketOpenAfterSubmission, values) => {
      try {
        setFieldValue('customerStatus', 'ACCEPTED');
        setFieldValue('driverAcceptanceReason', reason);
        setFieldValue('returnConcreteOnTruck', values.returnConcreteOnTruck || '');
        setFieldValue('returnConcreteSource', values.returnConcreteSource || 'DRIVER');
        waterIndexes?.forEach(idx => setFieldValue(`waterAddedEvents[${idx}].driverDidNotAdd`, true));
        if (values.customerStatus === 'ACCEPTED') {
          const ticket = await saveTicket(values);
          setSubmitted(true);
          reset(!keepTicketOpenAfterSubmission === true);
          return ticket;
        }
      } catch (error) {
        sendMessage(JSON.stringify({ type: 'debug', ticket: values, error: error.message }));
        Logger.error(
          `Mobile Ticket -  frontend/src/views/apps/entity-ref/mobile-ticket/useAutomaticTicketSubmission.js - ticketSubmission: ${error.message}`
        );
        throw error;
      }
    },
    [setSubmitted, saveTicket, reason, sendMessage, Logger, reset, waterIndexes, setFieldValue]
  );
  return { ticketSubmission };
};
