import cn from 'classnames';
import { AddedItems } from './AddedItems/AddedItems';
import styled from 'styled-components';
import { Container, desktopStyle } from './Style';
import { Form } from 'connex-cds';
import { MaterialSummaryDesktop } from './MaterialSummary/MaterialSummaryDesktop';
import BatchResults from './BatchResults/BatchResults';
import DeliveryInformation from './DeliveryInformation/DeliveryInformation';
import { mobileTicket } from '../../../../../../../api';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

const Styled = styled.div`
  &.dot-workflow-desktop {
    ${desktopStyle}
  }
`;

export const DotWorkflowDesktop = ({ data, ticket }) => {
  const { values } = Form.useFormContext();

  const filtereData = React.useMemo(() => {
    return {
      batch: data?.setup?.filter(_setup => _setup?.isBatchData) ?? [],
      consumption: data?.setup?.filter(_setup => !_setup?.isBatchData) ?? [],
    };
  }, [data]);

  return (
    <Styled className={cn('dot-workflow-desktop')}>
      <div className="batch-information">
        <MaterialSummaryDesktop source={filtereData?.consumption} results={data?.testsResult} />
        <Container>
          <div style={{ width: '50%' }}>
            <BatchResults source={filtereData?.batch} results={data?.testsResult} />
          </div>
          <div style={{ width: '50%' }}>
            <DeliveryInformation ticket={ticket ?? {}} />
          </div>
        </Container>
      </div>
      <div className="added-items">
        <AddedItems productCompliance={values?.productCompliance ?? {}} />
      </div>
    </Styled>
  );
};
