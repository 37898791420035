import { Form, Responsive } from 'connex-cds';
import React from 'react';
import { useDoOnce } from '../../../../../../../util/useDoOnce';
import { useTicketContext } from '../../../TicketContext';
import { ListDesktop } from './desktop/ListDesktop';
import { ListMobile } from './mobile/ListMobile';
import { get } from 'lodash';

export const CustomListComponent = props => {
  const {
    deleteItem,
    resetForm,
    parentFormContext: { errors },
    mode,
  } = Form.useFormContext();

  const { ticket } = useTicketContext();

  const handleDeleteClick = React.useCallback(
    item => {
      const isWaterProduct = Boolean(item?.waterProduct);
      const isDriverSellable = get(item, 'item.isDriverSellable', false);
      if (isWaterProduct || isDriverSellable) {
        deleteItem(item);
        resetForm({ reinitialize: true });
      }
    },
    [deleteItem, resetForm]
  );

  const handleRowClick = React.useCallback(
    row => {
      const isWaterProduct = Boolean(row?.waterProduct);
      const isDriverSellable = get(row, 'item.isDriverSellable', false);
      const ticketEventsExist =
        ticket?.ticketEvents && ['END_UNLOADING', 'PUMPING_COMPLETE'].some(event => ticket?.ticketEvents[event]);

      if ((ticketEventsExist || !row.suggestedToDriver) && (isDriverSellable || isWaterProduct)) {
        props.onRowClick?.(row);
      }
    },
    [props, ticket?.ticketEvents]
  );

  useDoOnce(() => {
    if (props.autoEdit && errors[props.paths.errors] && mode !== 'edit' && props?.data?.[0]) {
      // Have to wait a tick in order for things to be ready.
      setTimeout(() => {
        props.onRowClick?.(props.data[0]);
      });
    }
  });

  const disabled = React.useCallback(
    item => {
      const ticketEventsExist =
        Object.keys(ticket?.ticketEvents ?? {})?.filter(event => ['END_UNLOADING', 'PUMPING_COMPLETE'].includes(event))
          ?.length === 0;
      const suggestedToDriver = item?.suggestedToDriver;

      const isWaterProduct = Boolean(item?.waterProduct);
      if (isWaterProduct) {
        return ticketEventsExist && suggestedToDriver;
      }

      const isDriverSellable = get(item, 'item.isDriverSellable', false);
      if (!isDriverSellable) {
        return true;
      }

      return ticketEventsExist && suggestedToDriver;
    },
    [ticket?.ticketEvents]
  );

  const listProps = React.useMemo(() => {
    return {
      ...props,
      handleDeleteClick,
      handleRowClick,
      disabled,
      errors,
    };
  }, [disabled, errors, handleDeleteClick, handleRowClick, props]);

  return (
    <Responsive>
      <ListMobile {...listProps} />
      <ListDesktop {...listProps} />
    </Responsive>
  );
};
