import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization } from 'connex-cds';
import Style, { Label, TableBody, TableRow, Value, StyledHeader, TableCell } from './Style';
import { get } from 'lodash';

const { Translate } = Localization;
const testId = 'addedItemsGrid';

const Styled = styled.section`
  ${Style}
`;
const BatchResults = ({ source, results }) => {
  const translateMessage = Localization.useTranslateMessage();

  const rows = [];
  for (let i = 0; i < source?.length; i += 2) {
    rows.push(source?.slice(i, i + 2));
  }

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledHeader>
        <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
          <span>
            <Translate stringId="batchInformation" data-testid="batch" />
          </span>
        </div>
      </StyledHeader>

      <TableBody>
        {rows.map((group, index) => (
          <TableRow key={index}>
            {group?.map((_data, cellIndex) => {
              const [first, second] = _data?.paths ?? [];
              return (
                <TableCell key={cellIndex}>
                  <Label>
                    <Translate stringId={`${_data.labelStringId}`} data-testid="actual" />:{' '}
                  </Label>
                  <Value>
                    {get(results, second)
                      ? translateMessage(get(results, second, ''), { value: get(results, first, '') })
                      : !isNaN(Date.parse(get(results, first, ''))) && isNaN(get(results, first, ''))
                      ? new Date(get(results, first)).toLocaleDateString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                      : get(results, first, '')}
                  </Value>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Styled>
  );
};

export default BatchResults;
