import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Localization, Core, Form } from 'connex-cds';
import Style, { Label, StyledHeader, TableBody, TableCell, TableCellSpan, TableRow, Value } from './Style';

const { Translate } = Localization;
const testId = 'addedItemsGrid';

const Styled = styled.section`
  ${Style}
`;
const DeliveryInformation = ({ ticket }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledHeader>
        <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
          <span>
            <Translate stringId="deliveryInformation" data-testid="products-label" />
          </span>
        </div>
      </StyledHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="setup_entity-mt" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.sk?.customerName}</Value>
          </TableCell>
          <TableCellSpan>
            <Label>
              <Translate stringId="address" data-testid="batch" />
            </Label>
            <Value>{ticket?.origin?.address?.address1}</Value>
          </TableCellSpan>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="plant" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.origin?.description}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="orderId" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.dispatchOrder?.id}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="customer" data-testid="batch" />
            </Label>
            <Value>{ticket?.sk?.customerName}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="ticketId" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.id}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="poTag" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.customData?.orderDetails?.customerPoNumber}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="job" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.sk?.customerJob}</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="sp" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.sk?.customerJob}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="bridge" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="batchRecord" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="beginTime" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="volume" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="mixId" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="mixDesc" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="truckTag" data-testid="batch" />:{' '}
            </Label>
            <Value>{ticket?.vehicleId}</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="driver" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Label>
              <Translate stringId="qtyOrder" data-testid="batch" />:{' '}
            </Label>
            <Value>
              {translateMessage(ticket?.dispatchOrder?.totalOrdered?.uomCode, {
                value: ticket?.dispatchOrder?.totalOrdered?.value,
              })}
            </Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="qtyDelivered" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
          <TableCell>
            <Label>
              <Translate stringId="loads" data-testid="batch" />
            </Label>
            <Value>1</Value>
          </TableCell>
        </TableRow>
      </TableBody>
    </Styled>
  );
};

export default DeliveryInformation;
