import React from 'react';
import cn from 'classnames';
import { Localization } from 'connex-cds';
import { get } from 'lodash';
import styled from 'styled-components';

import style, { StyledBody, StyledFooter, StyledHeader, StyledTable } from './Style';

const { Translate } = Localization;

const Styled = styled.section`
  ${style}
`;

const testId = 'addedItemsGrid';
export const MaterialSummaryDesktop = ({ source, results }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <Styled data-testid={testId} className={cn('products-table-section')}>
      <StyledTable className={cn('products-table')}>
        <StyledHeader>
          <div className={cn('products-row')} data-testid={`${testId}-first-header-row`}>
            <Translate stringId="materialSummary" data-testid="products-label" />
          </div>
        </StyledHeader>
        <StyledBody>
          <div className={cn('products-row table-header')} data-testid={`${testId}-second-header-row`}>
            {source?.map(_data => {
              return <Translate stringId={`${_data?.labelStringId}`} data-testid="amount-label" />;
            })}
          </div>
          <div className={cn('products-row')} key={2} data-testid={`${testId}-row-${1}`}>
            {source?.map((_data, cellIndex) => {
              const [first, second] = _data?.paths ?? [];
              console.log('first', first);
              console.log('second', second);
              return (
                <div
                  key={`${testId}-row-${cellIndex}-column-product`}
                  data-testclass="cell-column-product"
                  data-testid={`${testId}-row-${1}-column-product`}
                  style={{ alignItems: 'center' }}
                >
                  {get(results, second, '')
                    ? translateMessage(get(results, second, ''), { value: get(results, first, '') })
                    : get(results, first, '')}
                </div>
              );
            })}
          </div>
        </StyledBody>
        <StyledFooter>
          <div className={cn('products-row')} />
        </StyledFooter>
      </StyledTable>
    </Styled>
  );
};
