import React from 'react';
import { Core, Drawer, Form, Layout, Modal } from 'connex-cds';
import { WaterRelease } from './WaterRelease';
import { useTicketContext } from '../../../../TicketContext';
import { useCompanySetup } from '../../../../MasterDataProvider';
import { UnFinalize } from '../../../modal/UnFinalize';

const { Container, Row } = Layout;

const viewStates = {
  RELEASE: 'RELEASE',
  SIGNATURE: 'SIGNATURE',
};

export const WaterReleaseModal = ({
  renderWaterReleaseSignature,
  handleWaterReleaseReset,
  handleWaterReleaseDeclined,
  handleWaterReleaseSubmitSignature,
}) => {
  const { ticket } = useTicketContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const {
    parentFormContext: { values },
  } = Form.useFormContext();
  const { openModal, closeModal } = Modal.useModalContext();
  const companySetup = useCompanySetup();
  const [view, setView] = React.useState(viewStates.RELEASE);

  const disableSubmitSignature = React.useMemo(
    () => !values?.waterReleaseSignature?.image,
    [values?.waterReleaseSignature]
  );

  const openPasswordModal = (callback = () => undefined) => {
    if (!companySetup?.data?.unFinalizePassword) {
      callback();
      closeDrawer();
      return;
    }

    const onOkay = password => {
      if (companySetup?.data?.unFinalizePassword === password) {
        callback();
        closeModal();
        closeDrawer();
      }
    };

    openModal({
      title: 'Password',
      component: <UnFinalize onOkay={onOkay} onCancel={closeModal} />,
    });
  };

  const handleAcceptWaterRelease = React.useCallback(() => {
    setView(viewStates.SIGNATURE);
  }, []);

  const handleDeclineWaterRelease = React.useCallback(() => {
    setView(viewStates.RELEASE);
    openPasswordModal(handleWaterReleaseDeclined);
  }, []);

  const handleCancelSignature = React.useCallback(() => {
    handleWaterReleaseReset();
    setView(viewStates.RELEASE);
  }, []);

  const handleSubmitSignature = React.useCallback(() => {
    setView(viewStates.SIGNATURE);
    openPasswordModal(handleWaterReleaseSubmitSignature);
  }, []);

  return (
    <>
      <Container flex={1} style={{ marginTop: '20px' }}>
        {view === viewStates.RELEASE && (
          <div>
            <WaterRelease />
            <Row>
              <Core.Button
                type="primary"
                stringId="accept"
                onClick={handleAcceptWaterRelease}
                data-testid="accept-button"
              />
              <Core.Button
                type="primary"
                stringId="decline"
                onClick={handleDeclineWaterRelease}
                data-testid="decline-button"
              />
            </Row>
          </div>
        )}
        {view === viewStates.SIGNATURE && (
          <div>
            {renderWaterReleaseSignature(ticket?.ticketId)}
            <Row style={{ float: 'right', padding: '20px 0' }}>
              <Core.Button
                onClick={handleCancelSignature}
                stringId="cancel-signature-button"
                data-testid="cancel-signature-button"
              />
              <Core.Button
                type="primary"
                disabled={disableSubmitSignature}
                onClick={handleSubmitSignature}
                stringId="submit-signature-button"
                data-testid="ok-button"
              />
            </Row>
          </div>
        )}
      </Container>
    </>
  );
};
