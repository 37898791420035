import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useTicketContext } from '../../../../TicketContext';
import style from './style';
import { Layout, Core, Form, Drawer, Modal, Localization } from 'connex-cds';
import {
  useCompanySetup,
  useFormsAndWaiversSetup,
  useVehicleSetup,
  useVehicleTypeSetup,
} from '../../../../MasterDataProvider';
import { UnFinalize } from '../../../modal/UnFinalize';
import { activeFormsAndWaiversArray } from '../../../../getActiveForms';

const { Column, Row } = Layout;

const Styled = styled.div`
  ${style}
`;

const DamageWaiver = () => {
  const { translateObjects } = Localization.useTranslateObjects();
  const { userLocale } = Localization.useLocalizationContext();
  const { ticket } = useTicketContext();
  const formsAndWaiversSetup = useFormsAndWaiversSetup();
  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const vehicleTypeSetup = useVehicleTypeSetup();
  const {
    Components: { DamageWaiverSignature },
    setFieldValue,
    values,
  } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { openModal, closeModal } = Modal.useModalContext();
  const [signature, setSignature] = useState(false);

  const activeFormsAndWaivers = React.useMemo(
    () =>
      activeFormsAndWaiversArray({
        ticket,
        formTypeCondition: 'DAMAGE',
        formsAndWaiversSetup,
        vehicleSetup,
        vehicleTypeSetup,
        companySetup,
        translateObjects,
        userLocale,
      }),
    [ticket, formsAndWaiversSetup, vehicleSetup, vehicleTypeSetup, companySetup, translateObjects, userLocale]
  );

  const disableSubmitSignature = React.useMemo(() => {
    return !values?.damageWaiverSignature?.image;
  }, [values?.damageWaiverSignature]);

  const handlePasswordProtect = React.useCallback(execFunction => {
    if (!companySetup?.data?.unFinalizePassword) {
      execFunction();
      closeDrawer();
      return;
    }

    const onOkay = password => {
      let finalized = false;
      if (companySetup?.data?.unFinalizePassword === password) {
        execFunction();
        finalized = true;
      }
      closeModal();
      if (finalized) {
        closeDrawer();
      }
    };

    openModal({
      title: 'Modal',
      component: <UnFinalize onOkay={onOkay} onCancel={closeModal} />,
    });
  });

  const handleDeclineDamageWaiver = React.useCallback(() => {
    handlePasswordProtect(() => {
      setFieldValue('damageWaiverSignature', undefined);
      setFieldValue('damageWaiverDeclined', true);
    });
  }, [setFieldValue]);

  const handleAcceptDamageWaiver = React.useCallback(() => {
    setSignature(true);
  }, []);

  const handleAcceptCancelSignature = React.useCallback(() => {
    setFieldValue('damageWaiverSignature', undefined);
    setSignature(false);
  }, [setFieldValue]);

  const handleSubmitSignature = React.useCallback(() => {
    handlePasswordProtect(() => setFieldValue('damageWaiverDeclined', false));
  }, [closeDrawer]);

  return (
    <Styled className={cn('damage-waiver')}>
      <Core.Spinner spin={vehicleSetup.isLoading || formsAndWaiversSetup.isLoading || companySetup.isLoading}>
        {!signature ? (
          <Column>
            <div data-testid="damageWaiver">
              <pre className={'damage-waiver-value'}>
                {activeFormsAndWaivers[0]?.label ?? activeFormsAndWaivers[0]?.content}
              </pre>
            </div>
            <Row>
              <Core.Button
                type="primary"
                stringId="accept"
                onClick={handleAcceptDamageWaiver}
                data-testid="accept-button"
              />
              <Core.Button
                type="primary"
                stringId="decline"
                onClick={handleDeclineDamageWaiver}
                data-testid="decline-button"
              />
            </Row>
          </Column>
        ) : (
          <div>
            <DamageWaiverSignature edit ticketId={ticket?.ticketId} />
            <Row style={{ float: 'right', padding: '20px 0' }}>
              <Core.Button
                onClick={handleAcceptCancelSignature}
                stringId="cancel-signature-button"
                data-testid="cancel-signature-button"
              />
              <Core.Button
                type="primary"
                disabled={disableSubmitSignature}
                onClick={handleSubmitSignature}
                stringId="submit-signature-button"
                data-testid="ok-button"
              />
            </Row>
          </div>
        )}
      </Core.Spinner>
    </Styled>
  );
};

export default DamageWaiver;
