import React from 'react';
import { mobileStyle } from './Style';
import styled from 'styled-components';
import { AddedItemsMobile } from './AddedItems/AddedItemsMobile';
import { Form } from 'connex-cds';
import { MaterialSummaryDesktop } from './MaterialSummary/MaterialSummaryDesktop';
import BatchResults from './BatchResults/BatchResults';
import DeliveryInformation from './DeliveryInformation/DeliveryInformation';

const StyledMobile = styled.div`
  ${mobileStyle}
`;

export const DotWorkflowMobile = ({ data, ticket }) => {
  const { values } = Form.useFormContext();

  const filtereData = React.useMemo(() => {
    return {
      batch: data?.setup?.filter(_setup => _setup?.isBatchData),
      consumption: data?.setup?.filter(_setup => !_setup?.isBatchData),
    };
  }, [data]);
  return (
    <StyledMobile>
      <AddedItemsMobile productCompliance={values?.productCompliance ?? {}} />
      <MaterialSummaryDesktop source={filtereData?.consumption} results={data?.testsResult} />
      <BatchResults source={filtereData?.batch} results={data?.testsResult} />
      <DeliveryInformation ticket={ticket ?? {}} />
    </StyledMobile>
  );
};
