import { css } from 'styled-components';

export default css`
.actions {
  @media (max-width: 410px) {
    margin-top: 115px;
    & > * {
      width: 48%;
    }
  }
  @media (min-width: 411px) {
    float: right;
    padding: 65px 0 20px 0;
  }
}
  .comment {
    textarea {
      width: 85%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .comment-footer {
    text-align: right;
  }

  .signature-outer {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 165px;
    & > button {
      position: absolute;
      bottom: -56px;
    }
    .signature-inner {
      border: 1px solid #ccc;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      overflow: hidden;
    }
  }

  .instruction {
    display: flex;
    justify-content: space-between;
    .warranty {
      color: #ff0000;
    }
  }
`;
