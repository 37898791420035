import { css } from 'styled-components';

export default css`
  flex-grow: 1;
  height: 100%;
  .column {
    height: 100%;
    div:first-child {
      height: 90%;
    }
  }
  .dynamic-waiver-value {
    padding: 10px;
    overflow: auto;
    font-family: 'Work Sans';
    white-space: pre-wrap;
  }
`;
