import cn from 'classnames';
import { Core, Drawer, Form } from 'connex-cds';
import React from 'react';
export const SignaturesSection = () => {
    const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
    const { Components, values} = Form.useFormContext();

    const allowProducerSignature = React.useMemo(() => {
      return !!values?.producerSignature?.image;
  }, [values.producerSignature]);

    const handleAcceptClick = React.useCallback((signatureType) => {
      openDrawer({
        onClose: () => closeDrawer(),
        titleStringId: signatureType === 'inspector' ? 'inspectorSignature' : 'producerSignature',
        component: signatureType === 'inspector' ? <Components.InspectorSignature edit signatureType={signatureType} /> : <Components.ProducerSignature edit signatureType={signatureType} />,
        width: '100%',
      });
    }, [openDrawer]);

    return (
        <div className={cn('added-items-row')}>
            <Core.Button stringId="inspectorSignature" onClick={() => handleAcceptClick('inspector')} type="primary" data-testid="accept-button" style={{ display: 'none'}}/>
            <Core.Button stringId="producerSignature" onClick={() => handleAcceptClick('producer')} type="primary" data-testid="accept-button" disabled={allowProducerSignature}/>
        </div>
    )
}