import common from '../common';
import errors from './errors';
import features from './features';
import themes from './theme';

export default {
  ...common,
  mobileTicket: 'Mobile Ticket',
  ...features,
  save: 'Speichern',
  username: 'Nutzername',
  password: 'Passwort',
  login: 'Anmeldung',
  required: 'Erforderlich',
  light: 'Licht',
  dark: 'Dunkel',
  rememberMe: 'Mich erinnern',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Datenschutz-Bestimmungen',
  customer: 'Kunde',
  project: 'Projekt',
  isBillable: 'Abrechenbar',
  dispatchDateTime: 'Datum',
  orderId: 'Befehl',
  ticketId: 'Fahrkarte',
  location: 'Ort',
  vehicleTypeName: 'Fahrzeug',
  product: 'Produkt',
  products: 'Produkt',
  salesTax: 'Mehrwertsteuer',
  totalPrice: 'Gesamtpreis',
  extendedPrice: 'Erweiterter Preis',
  destination: 'Reiseziel',
  productId: 'Produkt ID',
  statusCode: 'Status',
  vehicleTypeId: 'Fahrzeugtyp-ID',
  locationId: 'Standort-ID',
  customerId: 'Kundennummer',
  projectId: 'Projekt ID',
  dispatchTime: 'Abfertigungszeit',
  showAll: 'Zeige alles',
  hideAll: 'Versteck alles',
  undoChanges: 'Änderungen rückgängig machen',
  resetColumns: 'Spalten zurücksetzen',
  displayRowCount: `{count, plural, 
    =0 {Anzeige von # Zeilen} 
    one {# Zeile anzeigen} 
    other {Anzeige von # Zeilen} 
  }`,
  columnFilters: 'Spaltenfilter',
  visible: 'Sichtbar',
  hidden: 'Versteckt',
  itemDetail: 'Artikeldetail',
  quantity: 'Menge',
  unloadingMethod: 'Entlademethode',
  time: 'Zeit',
  eventStatus: 'Ereignisstatus',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'EA',
  LTR_abbr: 'L',
  YDQ_abbr: 'CY',
  GLL_abbr: 'GAL',
  MTQ_text: 'Kubikmeter',
  M3_text: 'Kubikmeter',
  EA_text: 'Stück',
  LTR_text: 'Liter',
  YDQ_text: 'Kubikmeter',
  GLL_text: 'Gallonen',
  ACR_text: 'Hektar',
  HUR_text: 'Stunde',
  INH_text: 'Zoll',
  FOT_text: 'Versfuß',
  YRD_text: 'Hof',
  MMT_text: 'Millimeter',
  CMT_text: 'Zentimeter',
  MTR_text: 'Meter',
  SMI_text: 'Meile (Statutsmeile)',
  KMT_text: 'Kilometer',
  INK_text: 'Quadratzoll',
  FTK_text: 'Quadratfuß',
  YDK_text: 'Quadratischer Hof',
  MIK_text: 'Quadratmeile',
  CMK_text: 'Quadratzentimeter',
  MTK_text: 'Kubikmeter',
  KMK_text: 'Quadratkilometer',
  INQ_text: 'Kubikzoll',
  MMQ_text: 'Kubikmillimeter',
  FTQ_text: 'Kubikfuß',
  CMQ_text: 'Kubikzentimeter',
  OZA_text: 'Flüssigunzen',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Milliliter',
  ONZ_text: 'Unze',
  LBR_text: 'Pfund',
  STN_text: 'Tonne',
  GRM_text: 'Gramm',
  KGM_text: 'Kilogramm',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton pro Quadratmillimeter',
  C62_text: 'Einer',
  NL_text: 'Belastung',
  MT_text: 'Matte',
  P1_text: 'Prozent',
  CEL_text: 'Grad Celsius',
  FAH_text: 'Grad Fahrenheit',
  BLL_text: 'Fass',
  CLT_text: 'Zentiliter',
  DLT_text: 'Deziliter',
  GLI_text: 'Gallone',
  '2U_text': 'Megagramm',
  SA_text: 'Sack',
  '60_text': 'Prozent Gewicht',
  BG_text: 'Tasche',
  SEC_text: 'Zweite',
  MIN_text: 'Minute',
  '64_text': 'Pfund pro Quadratzoll - Messgerät',
  SP_text: 'Regalpaket',
  MMK_text: 'Quadratmillimeter',
  MTN_text: 'Metrische Tonne',
  GT_text: 'Gigaton',
  '/NL_text': 'Pro Ladung',
  '/EA_text': 'Pro Menge',
  '/H/ + h_text': 'Pro Stunde',
  '/YQ/ + YDQ_text': 'Pro Kubikmeter',
  '/MQ/ + MTQ_text': 'Pro Kubikmeter',
  '/MN/ + MIN_text': 'Pro Minute',
  '/MT_text': 'Pro Meter',
  DSG_text: 'Dosierung',
  '/C_text': 'Pro 100 Gewicht',
  '64_2_text': 'Spezifisch',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liter}
    one {1 Liter}
    other {# Liter}
  }`,
  TO: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallonen}
    one {1 Gallone}
    other {# Gallonen}
  }`,
  EA: `{value, plural,
    =0 {0 Stück}
    one {1 Stück}
    other {# Stück}
  }`,
  MIN: `{value, plural,
    =0 {0 Minuten}
    one {1 Minute}
    other {# Minuten}
  }`,
  ACR: `{value, plural,
    =0 {0 Hektar}
    one {1 Acre}
    other {# Hektar}
  }`,
  HUR: `{value, plural,
    =0 {0 Std}
    one {1 Stunde}
    other {# Std}
  }`,
  INH: `{value, plural,
    =0 {0 Zoll}
    one {1 Zoll}
    other {# Zoll}
  }`,
  FOT: `{value, plural,
    =0 {0 Fuß}
    one {1 Versfuß}
    other {# Fuß}
  }`,
  YRD: `{value, plural,
    =0 {0 Meter}
    one {1 Hof}
    other {# Meter}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimeter}
    one {1 Millimeter}
    other {# Millimeter}
  }`,
  CMT: `{value, plural,
    =0 {0 Zentimeter}
    one {1 Zentimeter}
    other {# Zentimeter}
  }`,
  MTR: `{value, plural,
    =0 {0 Meter}
    one {1 Meter}
    other {# Meter}
  }`,
  SMI: `{value, plural,
    =0 {0 Meilen (Statutmeilen)}
    one {1 Meilen (Statut Mile)}
    other {# Meilen (Statutmeilen)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometer}
    one {1 Kilometer}
    other {# Kilometer}
  }`,
  INK: `{value, plural,
    =0 {0 Quadratzoll}
    one {1 Quadratzoll}
    other {# Quadratzoll}
  }`,
  FTK: `{value, plural,
    =0 {0 Quadratfuß}
    one {1 Quadratfuß}
    other {# Quadratfuß}
  }`,
  YDK: `{value, plural,
    =0 {0 Quadratmeter}
    one {1 Quadratischer Hof}
    other {# Quadratmeter}
  }`,
  MIK: `{value, plural,
    =0 {0 Quadratmeilen}
    one {1 Quadratmeile}
    other {# Quadratmeilen}
  }`,
  CMK: `{value, plural,
    =0 {0 Quadratzentimeter}
    one {1 Quadratzentimeter}
    other {# Quadratzentimeter}
  }`,
  MTK: `{value, plural,
    =0 {0 Kubikmeter}
    one {1 Kubikmeter}
    other {# Kubikmeter}
  }`,
  KMK: `{value, plural,
    =0 {0 Quadratkilometer}
    one {1 Quadratkilometer}
    other {# Quadratkilometer}
  }`,
  INQ: `{value, plural,
    =0 {0 Kubikzoll}
    one {1 Kubikzoll}
    other {# Kubikzoll}
  }`,
  MMQ: `{value, plural,
    =0 {0 Kubikmillimeter}
    one {1 Kubikmillimeter}
    other {# Kubikmillimeter}
  }`,
  FTQ: `{value, plural,
    =0 {0 Kubische Füße}
    one {1 Kubikfuß}
    other {# Kubische Füße}
  }`,
  CMQ: `{value, plural,
    =0 {0 Kubikzentimeter}
    one {1 Kubikzentimeter}
    other {# Kubikzentimeter}
  }`,
  OZA: `{value, plural,
    =0 {0 Flüssige Unzen}
    one {1 Flüssigunzen}
    other {# Flüssige Unzen}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    one {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    one {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Milliliter}
    one {1 Milliliter}
    other {# Milliliter}
  }`,
  ONZ: `{value, plural,
    =0 {0 Unzen}
    one {1 Unze}
    other {# Unzen}
  }`,
  LBR: `{value, plural,
    =0 {0 Pfund}
    one {1 Pfund}
    other {# Pfund}
  }`,
  STN: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,
  GRM: `{value, plural,
    =0 {0 Gramm}
    one {1 Gramm}
    other {# Gramm}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilogramm}
    one {1 Kilogramm}
    other {# Kilogramm}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnen}
    one {1 Tonne}
    other {# Tonnen}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    one {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    one {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newton pro Quadratmillimeter}
    one {1 Newton pro Quadratmillimeter}
    other {# Newton pro Quadratmillimeter}
  }`,
  C62: `{value, plural,
    =0 {0 Einer}
    one {1 Einer}
    other {# Einer}
  }`,
  NL: `{value, plural,
    =0 {0 Ladungen}
    one {1 Belastung}
    other {# Ladungen}
  }`,
  MT: `{value, plural,
    =0 {0 Matten}
    one {1 Matte}
    other {# Matten}
  }`,
  P1: `{value, plural,
    =0 {0 Prozent}
    one {1 Prozent}
    other {# Prozent}
  }`,
  CEL: `{value, plural,
    =0 {0 Grad Celsius}
    one {1 Grad Celsius}
    other {# Grad Celsius}
  }`,
  FAH: `{value, plural,
    =0 {0 Grad Fahrenheit}
    one {1 Grad Fahrenheit}
    other {# Grad Fahrenheit}
  }`,
  BLL: `{value, plural,
    =0 {0 Fässer}
    one {1 Fass}
    other {# Fässer}
  }`,
  CLT: `{value, plural,
    =0 {0 Zentiliter}
    one {1 Zentiliter}
    other {# Zentiliter}
  }`,
  DLT: `{value, plural,
    =0 {0 Deziliter}
    one {1 Deziliter}
    other {# Deziliter}
  }`,
  GLI: `{value, plural,
    =0 {0 Gallonen}
    one {1 Gallone}
    other {# Gallonen}
  }`,
  '2U': `{value, plural,
    =0 {0 Megagramm}
    one {1 Megagramm}
    other {# Megagramm}
  }`,
  SA: `{value, plural,
    =0 {0 Säcke}
    one {1 Sack}
    other {# Säcke}
  }`,
  60: `{value, plural,
    =0 {0 Prozent Gewicht}
    one {1 Prozent Gewicht}
    other {# Prozent Gewicht}
  }`,
  BG: `{value, plural,
    =0 {0 Taschen}
    one {1 Tasche}
    other {# Taschen}
  }`,
  SEC: `{value, plural,
    =0 {0 Sekunden}
    one {1 Zweite}
    other {# Sekunden}
  }`,
  64: `{value, plural,
    =0 {0 Pfund pro Quadratzoll - Messgerät}
    one {1 Pfund pro Quadratzoll - Messgerät}
    other {# Pfund pro Quadratzoll - Messgerät}
  }`,
  SP: `{value, plural,
    =0 {0 Regalpakete}
    one {1 Regalpaket}
    other {# Regalpakete}
  }`,
  MMK: `{value, plural,
  =0 {0 Quadratmillimeter}
  one {1 Quadratmillimeter}
  other {# Quadratmillimeter}
  }`,
  MTN: `{value, plural,
  =0 {0 Tonnen}
  one {1 metrische Tonne}
  other {# Tonnen}
  }`,
  GT: `{value, plural,
  =0 {0 Gigatonnen}
  one {1 Gigatonne}
  other {# Gigatonnen}
  }`,
  '/NL': `{value, plural,
  =0 {0 pro Ladung}
  one {1 pro Ladung}
  other {# pro Ladung}
  }`,
  '/EA': `{value, plural,
  =0 {0 pro Menge}
  one {1 pro Menge}
  other {# pro Menge}
  }`,
  '/H/ + h': `{value, plural,
  =0 {0 pro Stunde}
  one {1 pro Stunde}
  other {# pro Stunde}
  }`,
  '/YQ/ + YDQ': `{value, plural,
  =0 {0 pro Kubikyard}
  one {1 pro Kubikmeter}
  other {# pro Kubikmeter}
  }`,
  '/MQ/ + MTQ': `{value, plural,
  =0 {0 pro Kubikmeter}
  one {1 pro Kubikmeter}
  other {# pro Kubikmeter}
  }`,
  '/MN/ + MIN': `{value, plural,
  =0 {0 pro Minute}
  one {1 pro Minute}
  other {# pro Minute}
  }`,
  '/MT': `{value, plural,
  =0 {0 pro Meter}
  one {1 pro Meter}
  other {# pro Meter}
  }`,
  DSG: `{value, plural,
  =0 {0 Dosierung}
  one {1 Dosierung}
  other {# Dosierung}
  }`,
  '/C': `{value, plural,
  =0 {0 pro 100 Gewicht}
  one {1 pro 100 Gewicht}
  other {# pro 100 Gewicht}
  }`,
  '64_2': `{value, plural,
  =0 {0 Spezifisch}
  one {1 Spezifisch}
  other {# Spezifisch}
  }`,
  mixIdLabel: 'Sorte',
  orderedQtyLabel: 'Bestellte Menge',
  loadedQtyLabel: 'Geladene Menge',
  addedByCustomer: 'Added By Customer',
  PRINTED: 'Gebucht',
  LOADING_STARTED: 'Start Beladung',
  LOADING_COMPLETE: 'Ende Beladung',
  TO_JOB: 'Linkes Werk',
  ARRIVE_JOB: 'Ankunft Kunde',
  UNLOADING: 'Start Entladung',
  END_UNLOADING: 'Ende Entladung',
  LEAVE_JOB: 'Job verlassen',
  IN_YARD: 'Im Hof',
  PUMPING_STARTED: 'Pumpe starten',
  PUMPING_COMPLETE: 'Pumpe beenden',
  READY_TO_PUMP: 'Bereit zum Pumpen',
  FIRST_WCCONTACT: 'Erster Wasser-Zement-Kontakt',
  plantName: 'Werk',
  plantAddress: 'Werksadresse',
  loadingScheduleStart: 'Geplante Ladezeit',
  deliveryAddress: 'Lieferadresse',
  phoneNumber: 'Telefonnummer',
  unloadingScheduleStart: 'Geplantes Startdatum und Uhrzeit des Entladens',
  agreedUnloading: 'Vereinbarte Entladezeiten',
  deliveryInformation: 'Lieferinformationen',
  next: 'kommend',
  material: 'Stoff',
  loadingPoint: 'Beladeort',
  unloadingPoint: 'Entladeort',
  comment: 'Kommentar',
  sapDeliveryNumber: 'SAP-Liefernummer (BCC-Nummer)',
  salesOrderNumber: 'Kundenbestellnummer und Artikellinie',
  wcDateTime: 'Datum und Uhrzeit mit C-Kontakt',
  po: 'Bestellnummer',
  orderedByName: 'Bestellung mit Namen aufgestellt',
  orderedByPhone: 'Bestellung per Telefonnummer erteilt',
  resourceId: 'Ressourcen-ID',
  totalLoadedQty: 'Gesamtbelastung qty einschließlich dieser Last',
  spacing: 'Lastplatz',
  deliveryFlow: 'Lieferfluss',
  timeOnSite: 'Gesamtzeit vor Ort/bestellte Entladungszeit',
  salesText: 'Verkaufstext (Material ID)',
  strengthClass: 'Kraftklasse',
  environmentExposure: 'Umweltbelastung',
  metExposureClasses: 'Erfüllungsklassen erfüllt',
  dmax: 'Dmax',
  standardCert: 'Standard (Zertifizierung)',
  workabilityTarget: 'Kundenarbeitsfähigkeitsziel',
  stoneQuality: 'Steinqualität',
  sandQuality: 'Sandqualität',
  targetWC: 'Ziel mit Rezept mit C/c-Verhältnis',
  controlClass: 'Kontrollklasse',
  chlorideClass: 'Chloridklasse',
  typeAndStrength1: 'Art der Zement- und Stärkeklasse 1',
  typeAndStrength2: 'Art der Zement- und StärkeClass 2',
  typeOfAddition: 'Art der Addition',
  typeOfAdmixture: 'Art der Beimischung',
  orderedTemp: 'Bestellte Temperatur',
  fibers1: 'Typ und Inhalt von Fasern 1',
  fibers2: 'Typ und Inhalt von Fasern 2',
  calculatedMaturityTime: 'Berechnete Reifezeit aus der Kontaktzeit mit C/c',
  mixIdentity: 'Identität mischen',
  grade: 'Grad',
  mixType: 'Typ Mix',
  maxAggSize: 'Maxe Agg-Größe',
  maxAggType: 'Max Agg Typ',
  cementType: 'Zementtyp',
  admixtures: 'Beimischungen',
  addmixDescription: 'Addmix Beschreibung',
  slump: 'Einbruch',
  minCementContent: 'Minzementinhalt',
  maxWatCemRatio: 'Maximales Wasser/Zementverhältnis',
  maxWaterToAdd: 'Max Wasser zum Hinzufügen',
  mortarMinWorkingLife: 'Mörtel min Arbeitsleben',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chlorid',
  signedByHanson: 'Unterzeichnet von Hanson',
  warmStamp: 'Warmer Stempel',
  coldStamp: 'Kaltstempel',
  containsSikatard: 'Enthält Sikatard',
  containsDelvo: 'Enthält Delvo',
  generalDelivery: 'Allgemeine Lieferinformationen',
  materialInfo: 'Materialinformationen',
  water: 'Wasser',
  materialService: 'Material / Service',
  returnedMaterial: 'Rückmenge',
  returnedConcrete: 'Rückmenge',
  waterAdded: 'Wasserzugabe',
  concreteOnTruck: 'Beton auf LKW',
  concreteOnTruckField: 'Aktivieren Sie „Beton auf LKW-Feld“',
  materialOnTruckField: 'Aktivieren Sie „Material auf LKW-Feld“',
  reason: 'Grund',
  customerAddedWater: 'Kunde hat Wasser hinzugefügt',
  addWater: 'Wasserzugabe',
  addedAt: 'Hinzugefügt am',
  addMaterialService: 'Material/Service hinzufügen',
  customerAddedProduct: 'Vom Kunden hinzugefügtes Produkt',
  addReturnedMaterial: 'Retourniertes Material hinzufügen',
  leftover: 'Ausruhen',
  submitAcceptance: 'Speichern',
  accepted: 'Akzeptiert',
  rejected: 'Abgelehnt',
  customerAcceptance: 'Kundenakzeptanz',
  finalize: 'Beenden',
  materialSummary: 'Materialzusammenfassung',
  mix: 'Sorte',
  ordered: 'Bestellt',
  loaded: 'Liefermenge',
  totalOrdered: 'Gesamtbestellmenge',
  loadedOrderQuantity: 'Geladene Bestellmenge',
  loadedOrder: 'Geladene Bestellung',
  driverComment: 'Fahrerkommentare',
  customerComment: 'Kundenkommentar',
  editComment: 'Kommentar bearbeiten',
  addComment: 'Einen Kommentar hinzufügen',
  additionalInfo: 'Zusätzliche Information',
  onBoard: 'Am Bord',
  driver: 'Treiber',
  commentInstruction: 'Kommentaranweisung',
  signatureInstruction: 'Unterschrift des Kunden',
  customerSignature: 'Unterschrift des Kunden',
  general: 'Allgemein',
  status: 'Status',
  updateStatusTime: 'Anpassung der Statuszeit',
  delete: 'Löschen',
  cancel: 'Abbrechen',
  back: 'Zurück',
  add: 'Hinzufügen',
  done: 'Speichern',
  ok: 'OK',
  decline: 'Abfall',
  Submit: 'Speichern',
  submit: 'Speichern',
  Time: 'Zeit',
  clear: 'Abbrechen',
  lastRefresh: 'Letzte Aktualisierung',
  refreshEnabled: 'Aktualisieren aktivieren',
  id: 'Ausweis',
  name: 'Name',
  invoiceable: 'Rechnungspflichtig',
  provideSignature: 'Unterschrift bereitstellen',
  ticket: 'Lieferung',
  details: 'Einzelheiten',
  qrCode: 'QR',
  driverAcceptanceReason: 'Grund',
  driverAcceptance: 'Grund für fehlende Unterschrift',
  'payment-method': 'Zahlungsarten',
  'add-payment-method': 'Zahlungsmethode hinzufügen',
  'reason-code': 'Grundcode',
  'add-reason-code': 'Ursachencode hinzufügen',
  'qr-code': 'QR Code',
  'unloading-method': 'Entlademethoden',
  'add-unloading-method': 'Entlademethode hinzufügen',
  'driver-comment': 'Fahrerkommentare',
  'add-driver-comment': 'Fahrerkommentar hinzufügen',
  'driver-sellable': 'Treiber Verkaufsartikel',
  vehicle: 'Fahrzeuge',
  'add-vehicle': 'Fahrzeug hinzufügen',
  'vehicle-type': 'Fahrzeugtypen',
  vehicleTypes: 'Fahrzeugtypen',
  vehicleType: 'Fahrzeugtyp',
  'add-vehicle-type': 'Fahrzeugtyp hinzufügen',
  countries: 'Länder',
  country: 'Land',
  type: 'Kunst',
  uom: 'Maßeinheit',
  mandatory: 'Obligatorisch',
  qrTemplate: 'QR-Code-Vorlage',
  includeMixInfo: 'Fügen Sie Mix-Informationen in den QR-Code ein',
  includeTicketEvents: 'Fügen Sie Ticket-Events in den QR-Code ein',
  quantityField: 'Mengenfeld aktivieren',
  timeField: 'Zeitfeld aktivieren',
  reasonField: 'Grundfeld aktivieren',
  driverInstructions: 'Fahreranweisungen',
  waitingForTicket: 'Warten auf Ticket...',
  createTestTicketForTruck: 'Testticket für LKW erstellen',
  exceedsLoadedQuantity: 'Der Wert darf die geladene Menge von nicht überschreiten {loadedQuantity}.',
  maxQuantity: 'Maximale Menge',
  maxQuantityError: 'Die Menge darf die definierte Höchstmenge von {maxQty} nicht überschreiten',
  maxValueError: 'Der Wert kann das definierte Maximum von {maxQty} nicht überschreiten',
  minValueError: 'Wert kann nicht weniger als definiert sein, ein Minimum von {minqty}',
  notAuthorized: 'Nicht berechtigt',
  enterTruckNumber: 'Unterschrift des Kunden',
  setTruckNumber: 'LKW-Nummer stellen',
  commentList: 'Kommentarliste',
  list: 'Auswählen',
  gridPreferences: 'Rastereinstellungen',
  loadingUnloadingPoints: 'Be- und Entladeort',
  activity: 'Aktivität',
  crn: 'Crn',
  update: 'Speichern',
  CONCRETE: 'Beton',
  WATER: 'Wasser',
  ADDITIVE: 'Zusatzstoff',
  'submit-signature-button': 'Speichern',
  'cancel-signature-button': 'Abbrechen',
  uomCode: 'Standard-UOM',
  'product-type': 'Produkttypen',
  'add-product-type': 'Produkttyp hinzufügen',
  'add-product': 'Produkt hinzufügen',
  'setup_driver-comment': 'Fahrerkommentare',
  setup_product: 'Produkte',
  'setup_product-type': 'Produkttypen',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Ursachencodes',
  'setup_unloading-method': 'Entlademethoden',
  'setup_entity-mt': 'Unternehmen',
  setup_vehicle: 'Fahrzeuge',
  'setup_vehicle-type': 'Fahrzeugtypen',
  'setup_data-retentions': 'Vorratsdatenspeicherung',
  'add-data-retentions': 'Datenbindung hinzufügen',
  'data-retentions': 'Vorratsdatenspeicherung',
  setup_carriers: 'Träger',
  'add-carriers': 'Träger hinzufügen',
  carriers: 'Träger',
  'setup_concrete-classes': 'Materialkonformität',
  'add-concrete-classes': 'Fügen Sie Materialkonformität hinzu',
  'concrete-classes': 'Materialkonformität',
  'setup_forms-and-waivers': 'Formen & Verzichtserklärungen',
  'add-forms-and-waivers': 'Fügen Sie Formulare und Verzichtserklärungen hinzu',
  'forms-and-waivers': 'Formen & Verzichtserklärungen',
  companyFleet: 'Firmenflotte',
  category: 'Kategorie',
  interval: 'Intervall',
  orders: 'Bestellungen und Tickets',
  '30days': '30 Tage',
  '60days': '60 Tage',
  '90days': '90 Tage',
  '1year': '1 Jahr',
  '3years': '3 Jahre',
  waterMandatory: 'Wasser obligatorisch',
  qrCodeTemplate: 'QR-Codevorlage',
  waterConfig: 'Optionale Felder',
  waterProduct: 'Wasser',
  waterProductType: 'Wasserprodukttyp',
  defaultWaterProduct: 'Standard-Wasserprodukt',
  invalidTemplate: 'Ungültige Vorlage',
  siteAddress: 'Adresse der Baustelle',
  siteContactName: 'Kontaktname der Baustelle',
  siteContactPhoneNumber: 'Kontakttelefonnummer für die Baustelle',
  'clear-signature-button': 'Unterschrift zurücksetzen',
  customerCommentEnabled: 'Kundenkommentar aktivieren',
  driverCommentEnabled: 'Fahrerkommentar aktivieren',
  driverCommentMaxCharacters: 'Maximale Länge',
  customerCommentMaxCharacters: 'Maximale Länge',
  timeZone: 'Zeitzone',
  driverDidNotAdd: 'Keine Wasserzugabe',
  maxLineItems: 'Max Anzahl von Werbebuchungen',
  oneLineItem: 'Es muss mindestens 1 Werbebuchung vorhanden sein',
  signatureSiteContact: 'Kundenname',
  ticketEvents: 'Ticket-Veranstaltungen',
  hideEvent: 'Ereignis ausblenden',
  editable: 'Bearbeitbar',
  ticketDetail: `Fahrkarte #{value}`,
  statusTimes: 'Statuszeiten',
  materialAndDeliveryInfo: 'Material- und Lieferinformationen',
  customerRejection: 'Kundenablehnung',
  'submit-rejection-button': 'Absage übermitteln',
  accept: 'Akzeptieren',
  reject: 'Ladung ablehnen',
  signature: 'Unterschrift',
  'no-signature': 'Keine Unterschrift',
  unFinalizePassword: 'Unfinalisieren Passwort',
  waterInWorkflow: 'Aktivieren Sie den Wasserschritt im Workflow',
  multicountry: 'Mehrländer',
  timestampAfterSequence: 'WARNUNG: Die Zeit sollte nicht vor dem vorherigen Zeitstempel liegen',
  timestampBeforeSequence: 'WARNUNG: Die Zeit sollte nicht nach dem nächsten Zeitstempel liegen',
  noRestQuantity: 'Keine Rückmenge',
  returnedMaterialMandatory: 'Muss mit dem zurückgegebenen Materialschritt interagieren',
  driverCommentMaxChars: 'Die Länge kann maxe nicht überschreiten von {maxLength} Zeichen.',
  QrCodeEnabled: 'Aktivieren Sie den QR-Code',
  QrMethod_linkBased: 'Linkbasierte',
  QrMethod_contentBased:
    'Inhaltsbasiert, HINWEIS: Inhaltsbasierte QR-Codes unterliegen Zeichenbeschränkungen, die bei mehr als 1024 Zeichen zu Problemen führen können.',
  QrMethod_contentBasedWarning:
    'HINWEIS: Dieser QR-Code enthält {amount} Zeichen, die von einigen Telefonen möglicherweise nicht gelesen werden können.',
  returnedMaterialInWorkflow: 'Aktivieren Sie den zurückgegebenen Materialschritt im Workflow',
  materialServiceInWorkflow: 'Aktivieren Sie den Material-/Serviceschritt im Workflow',
  totalPumpedQuantity: 'Gesamtpumpenmenge',
  concrete: 'Beton',
  materialComplianceEnabled: 'Materialkonformität aktivieren',
  pumpStatusTimes: 'Pumpenstatuszeiten',
  pollingInterval: 'Wahlintervall (Sekunden)',
  tenSecs: 'Das Intervall muss mindestens 10 Sekunden betragen',
  materialCompliance: 'Materialkonformität',
  consistenceValue: 'Konsistenz',
  consistenceIsCompliant: 'Die Konsistenz entspricht BS8500-2',
  airContentValue: 'Luftinhalt',
  airContentIsCompliant: 'Der Luftgehalt entspricht BS8500-2',
  consistenceIsCompliantShort: 'Konsistenz entspricht',
  airContentIsCompliantShort: 'Der Luftinhalt entspricht',
  compliance: 'Materialkonformität mit BS8500-2',
  termsAndConditionsEnabled: 'Aktivieren Sie die Bedingungen Formular',
  termsAndConditions: 'Geschäftsbedingungen',
  damageWaiverEnabled: 'Schadensverzichtsform aktivieren',
  damageWaiver: 'Schadenserscheinungsverzicht',
  damageWaiverAcknowledged: 'Schadensersatz anerkannt',
  dynamicWaiver: 'Dynamischer Verzicht',
  dynamicWaiverEnabled: 'Aktivieren Sie die Form der dynamischen Verzichtserklärung',
  ticketListEnabled: 'Ticketliste aktivieren',
  clearTicketAfterSubmission: 'Löschen Sie das Ticket nach der Übermittlung',
  keepTicketOpenAfterSubmission: 'Ticket nach der Übermittlung offen halten',
  displayTicketList: 'Ticketliste anzeigen',
  supportedLanguages: 'Unterstützte Sprachen',
  allow: 'Erlauben',
  default: 'Standard',
  isCertificationLossTriggerForReason: 'Verursacht Zertifizierungsverlust',
  isCertificationLossTriggerForProduct: 'Verursacht Zertifizierungsverlust',
  translations: 'Übersetzungen',
  family_name: 'Familienname, Nachname',
  given_name: 'Vorname',
  email: 'Email',
  role: 'Rolle',
  inviteUser: 'Benutzer einladen',
  carrier: 'Träger',
  emailOrPhoneNumber: 'Email',
  usersEditor: 'Benutzereditor',
  verifyPassword: 'Passwort bestätigen',
  verificationCode: 'Bestätigungscode',
  register: 'Registrieren',
  registerInstructions: 'Bitte füllen Sie dieses Formular aus, um Ihre Registrierung abzuschließen.',
  'password-requirements':
    'Ihr Passwort muss mindestens 8 Zeichen betragen, mindestens 1 Großbuchstaben, 1 Kleinbuchstaben, 1 und 1 spezielles Zeichen enthalten.',
  passwordMinCharacters: 'Das Passwort muss 8 oder mehr Zeichen enthalten.',
  passwordMaxCharacters: 'Passwort kann nicht mehr als 99 Zeichen enthalten.',
  passwordLowerCase: 'Das Passwort muss 1 oder mehrere niedrigere Gehäuseschreiben enthalten.',
  passwordUpperCase: 'Das Passwort muss 1 oder mehr obere Fallbrief enthalten.',
  passwordNumber: 'Das Passwort muss 1 oder mehr numerische Zeichen enthalten.',
  passwordSpecialCharacter: 'Das Passwort muss 1 oder mehr spezielles Zeichen enthalten.',
  passwordsDontMatch: 'Passwörter stimmen nicht überein!',
  success: 'Erfolg!',
  'registration-success': 'Ihre Registrierung war erfolgreich.',
  'post-registration-instructions':
    'Sie haben den Registrierungsprozess abgeschlossen. Du darfst dich jetzt einloggen.',
  createDate: 'Erstellungsdatum',
  customerPo: 'Kundendo-PO-Nummer',
  specification: 'Spezifikation',
  description: 'Beschreibung',
  classType: 'Klassentyp',
  minimumValue: 'Mindestwert',
  maximumValue: 'Höchster Wert',
  TrackIt: 'Verfolge es',
  trackIt3PContext: 'Trackit 3p-Kontext',
  inviteLanguage: 'Sprache einladen',
  truckNumber: 'LKW Nummer',
  adverseTemperature:
    'Die Umgebungstemperatur zum Zeitpunkt des Mischens war so hoch, dass wir nicht garantieren können, dass der Beton im Temperaturbereich von 5-35 °C gemäß EN 206 liegt.',
  environmentalNote:
    'Diese Ladung enthält einen Umweltwirkstoff, der 0,03 Gewichtsprozent des Betons nicht überschreitet. Wasser, das dieses Material für die Betonherstellung enthält, entspricht der Norm BS EN 1008.',
  unloadingMethodEnabled: 'Entlademethode aktivieren',
  warrantyVoided: 'Materialgarantie erlischt',
  materialWarrantyVoidedEnabled:
    'Wenn das Produkt für Zertifizierungsverlust oder Produkt & Grundcode verwendet wird, zeigen Sie eine Zertifizierungsverlustnotiz zum Zeitpunkt der Unterschrift an.',
  'setup_templates-mt': 'Vorlagen',
  copyFrom: 'Kopie von...',
  copy: 'Kopieren',
  content: 'Inhalt',
  WARNINGS: 'Warnungen',
  TERMS: 'Bedingungen',
  DAMAGE: 'Schaden',
  ADDITIONALWAIVER: 'Zusätzlicher Verzicht',
  DYNAMICWAIVER: 'Dynamischer Verzicht',
  source: 'Quelle',
  sensorUnable: 'Der Sensor kann die übrig gebliebene Menge nicht bestimmen.',
  PROBE: 'Sonde',
  DRIVER: 'Treiber',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Aktivieren Sie das Wasserfreigabeformular',
  waterRelease: 'Wasserfreisetzung',
  waterReleaseAcknowledged: 'Wasserfreisetzung bestätigt',
  'assessment-risk': 'Risikobewertung',
  'setup_assessment-risk': 'Risikobewertung',
  'add-assessment-risk': 'Risikobewertung hinzufügen',
  enterRecipientsButton: 'Geben Sie den/die Empfänger ein.',
  recipientsInstructions: 'Geben Sie die E-Mail-Adresse durch ein Komma getrennt ein',
  enter: 'Eingeben',
  invalidEmailAddress: 'ungültige E-Mail-Adresse',
  personalDataPolicy:
    'Durch die Eingabe personenbezogener Daten in dieses Formular bestätigen Sie, dass Sie über die erforderlichen Berechtigungen zur Weitergabe dieser personenbezogenen Daten verfügen',
  subcategory: 'Unterkategorie',
  isRequired: 'Ist erforderlich',
  riskAssessmentEnabled: 'Aktivieren Sie die Risikobewertung',
  riskAssessment: 'Risikobewertung',
  yes: 'Ja',
  no: 'Nein',
  sourceOptionsSensors: 'Quellenoptionen (Sensoren)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonde',
  availableCustomValues: 'Verfügbare benutzerdefinierte Werte',
  ERR_CODE__DUPLICATE: 'Duplikat',
  ERR_CODE__DEFAULT_VALUE: 'Es gibt bereits einen Standardwert',
  ERR_CODE__EXISTING_FORM: 'Für dieses Fahrzeug und diesen Typ gibt es bereits ein aktives Formular',
  availableValues: 'Verfügbare Werte',
  pump: 'Pumpe',
  DanskName: 'Danskenname',
  GermanName: 'Deutscher Name',
  EnglishName: 'Englischer Name',
  BritainName: 'Großbritanniener Name',
  SpanishName: 'Spanischer Name',
  FrenchName: 'Französischer Name',
  NederlandsName: 'Name von Nederlands',
  NorskName: 'Norsk-Name',
  SvenskaName: 'Svenska Name',
  ItalianName: 'Italienisch Name',
  PolishName: 'Polnischer Name',
  DanskNameDefault: 'Danskname (Standard)',
  GermanNameDefault: 'Deutscher Name (Standard)',
  EnglishNameDefault: 'Englischer Name (Standard)',
  BritainNameDefault: 'Großbritanniener Name (Standard)',
  SpanishNameDefault: 'Spanischer Name (Standard)',
  FrenchNameDefault: 'Französischer Name (Standard)',
  NederlandsNameDefault: 'Name von Nederlands (Standard)',
  NorskNameDefault: 'Norsk-Name (Standard)',
  SvenskaNameDefault: 'Svenska Name (Standard)',
  ItalianNameDefault: 'Italienisch Name (Standard)',
  PolishNameDefault: 'Polnischer Name (Standard)',
  DanskDescription: 'Dansk Beschreibung',
  GermanDescription: 'Deutsche Beschreibung',
  EnglishDescription: 'Englische Beschreibung',
  BritainDescription: 'Großbritannien Beschreibung',
  SpanishDescription: 'Spanische Beschreibung',
  FrenchDescription: 'Französische Beschreibung',
  NederlandsDescription: 'Nederlands Beschreibung',
  NorskDescription: 'Norsk Beschreibung',
  SvenskaDescription: 'Svenska Beschreibung',
  ItalianDescription: 'Italienisch Beschreibung',
  PolishDescription: 'Polnische Beschreibung',
  DanskDescriptionDefault: 'Dansk Beschreibung (Standard)',
  GermanDescriptionDefault: 'Deutsche Beschreibung (Standard)',
  EnglishDescriptionDefault: 'Englische Beschreibung (Standard)',
  BritainDescriptionDefault: 'Großbritannien Beschreibung (Standard)',
  SpanishDescriptionDefault: 'Spanische Beschreibung (Standard)',
  FrenchDescriptionDefault: 'Französische Beschreibung (Standard)',
  NederlandsDescriptionDefault: 'Nederlands Beschreibung (Standard)',
  NorskDescriptionDefault: 'Norsk Beschreibung (Standard)',
  SvenskaDescriptionDefault: 'Svenska Beschreibung (Standard)',
  ItalianDescriptionDefault: 'Italienisch Beschreibung (Standard)',
  PolishDescriptionDefault: 'Polnische Beschreibung (Standard)',
  DanskContent: 'Danskinhalt',
  GermanContent: 'Deutscher Inhalt',
  EnglishContent: 'Englischer Inhalt',
  BritainContent: 'Großbritannieninhalte',
  SpanishContent: 'Spanischer Inhalt',
  FrenchContent: 'Französischer Inhalt',
  NederlandsContent: 'Inhalt von Nederlands',
  NorskContent: 'Norsk-Inhalt',
  SvenskaContent: 'Svenska-Inhalt',
  ItalianContent: 'Italienisch-Inhalt',
  PolishContent: 'Polnischer-Inhalt',
  DanskContentDefault: 'Danskinhalt (Standard)',
  GermanContentDefault: 'Deutscher Inhalt (Standard)',
  EnglishContentDefault: 'Englischer Inhalt (Standard)',
  BritainContentDefault: 'Großbritannieninhalte (Standard)',
  SpanishContentDefault: 'Spanischer Inhalt (Standard)',
  FrenchContentDefault: 'Französischer Inhalt (Standard)',
  NederlandsContentDefault: 'Nederlands Inhalt (Standard)',
  NorskContentDefault: 'NORK-Inhalt (Standard)',
  SvenskaContentDefault: 'Svenska-Inhalt (Standard)',
  ItalianContentDefault: 'Italienisch-Inhalt (Standard)',
  PolishContentDefault: 'Polnischer-Inhalt (Standard)',
  DanskCategory: 'Dansk-Kategorie',
  GermanCategory: 'Deutsche Kategorie',
  EnglishCategory: 'Englische Kategorie',
  BritainCategory: 'Großbritannienkategorie',
  SpanishCategory: 'Spanische Kategorie',
  FrenchCategory: 'Französische Kategorie',
  NederlandsCategory: 'Nederlands Kategorie',
  NorskCategory: 'Norsk-Kategorie',
  SvenskaCategory: 'Svenska-Kategorie',
  ItalianCategory: 'Italienisch-Kategorie',
  PolishCategory: 'Polnische-Kategorie',
  DanskCategoryDefault: 'Danskkategorie (Standard)',
  GermanCategoryDefault: 'Deutsche Kategorie (Standard)',
  EnglishCategoryDefault: 'Englische Kategorie (Standard)',
  BritainCategoryDefault: 'Großbritannienkategorie (Standard)',
  SpanishCategoryDefault: 'Spanische Kategorie (Standard)',
  FrenchCategoryDefault: 'Französische Kategorie (Standard)',
  NederlandsCategoryDefault: 'Nederlands Kategorie (Standard)',
  NorskCategoryDefault: 'Norsk-Kategorie (Standard)',
  SvenskaCategoryDefault: 'Svenska-Kategorie (Standard)',
  ItalianCategoryDefault: 'Italienisch-Kategorie (Standard)',
  PolishCategoryDefault: 'Polnische-Kategorie (Standard)',
  DanskSubcategory: 'Dansk-Unterkategorie',
  GermanSubcategory: 'Deutsche Unterkategorie',
  EnglishSubcategory: 'Englische Unterkategorie',
  BritainSubcategory: 'Großbritannien Unterkategorie',
  SpanishSubcategory: 'Spanische Unterkategorie',
  FrenchSubcategory: 'Französische Unterkategorie',
  NederlandsSubcategory: 'Unterkategorie Nederlands',
  NorskSubcategory: 'Norsk-Unterkategorie',
  SvenskaSubcategory: 'Svenska-Unterkategorie',
  ItalianSubcategory: 'Italienisch-Unterkategorie',
  PolishSubcategory: 'Polnische Unterkategorie',
  DanskSubcategoryDefault: 'Dansk-Unterkategorie (Standard)',
  GermanSubcategoryDefault: 'Deutsche Unterkategorie (Standard)',
  EnglishSubcategoryDefault: 'Englische Unterkategorie (Standard)',
  BritainSubcategoryDefault: 'Großbritannien Unterkategorie (Standard)',
  SpanishSubcategoryDefault: 'Spanische Unterkategorie (Standard)',
  FrenchSubcategoryDefault: 'Französische Unterkategorie (Standard)',
  NederlandsSubcategoryDefault: 'Unterkategorie von Nederlands (Standard)',
  NorskSubcategoryDefault: 'Norsk-Unterkategorie (Standard)',
  SvenskaSubcategoryDefault: 'Svenska-Unterkategorie (Standard)',
  ItalianSubcategoryDefault: 'Italienisch-Unterkategorie (Standard)',
  PolishSubcategoryDefault: 'Polnische-Unterkategorie (Standard)',
  priorSignatures: 'Vorherige Unterschriften',
  damageWaiverSignature: 'Unterschrift der Schadensersatzerklärung',
  waterReleaseSignature: 'Unterschrift zur Wasserfreisetzung',
  errors,
  themes,
  ticketList: 'Ticketliste',
  isPDFRecipientListEnable: 'PDF-Empfängerliste auf dem Kundensignaturbildschirm aktiviert',
  isRejectLoadsEnable: 'Ablehnen von Ladungen nach dem Eintreffen vor Ort aktivieren',
  rejectLoad_returnedMaterial: 'Schaltfläche Workflow für zurückgegebenes Material',
  rejectLoad_returnedAndFinalize: 'Workflow-Schaltflächen "Zurückgegebenes Material" und "Abschließen',
  driverRejection: 'Ladung ablehnen',
  submitRejection: 'Ablehnung einreichen',

  costAndPaymentsSummary: 'Kosten- und Zahlungsübersicht',
  subtotal: 'Zwischensumme',
  tax: 'Steuer',
  remainingBalance: 'Die verbleibende Summe',
  paymentAmount: 'Zahlungsbetrag',
  total: 'Gesamt',
  paymentMethod: 'Bezahlverfahren',
  paymentProducts: 'Produkte',
  unitPrice: 'Einzelpreis',
  amount: 'Menge',
  otherPaymentMethod: 'Andere Bezahlmethode',
  method: 'Methode',
  paymentOptions: 'Zahlungsmöglichkeiten',
  typeAdditionalInfo: 'Geben Sie hier zusätzliche Informationen ein',
  COD: 'Barzahlung bei Lieferung',
  AMEX: 'American Express',
  CASH: 'Kasse',
  CHECK: 'Überprüfen',
  'CREDIT CARD': 'Kreditkarte',
  ACCOUNT: 'Offener Account',
  UNKNOWN: 'Unbekannt',
  remainingMaterial: 'Rückmenge',
  splitLoad: 'Aufteilung der Ladung',
  isEnablePaymentAndCostWorkflows: 'Zahlungs- und Kostenabläufe aktivieren',
  airTempValue: 'Lufttemperatur',
  concreteTempValue: 'Beton Temperatur',
  cylinderNumber: 'Zylinder Nummer',
  materialOnTruck: 'Material auf LKW',
  waterLocation: 'Vandplacering',
  dotWorkflow: 'DOT-Workflow',
  inspectorSignature: 'Inspektorens Unterschrift',
  producerSignature: 'Produzentenunterschrift',
  addedItems: 'Hinzugefügte Elemente',
  producerSignatureContact: 'Produzentenname',
  inspectorSignatureContact: 'Inspektorenname',
  waterAtPlant: 'Wasser auf dem Werksgelände (gl)',
  waterAtJobsite: 'Wasser auf der Baustelle (gl)',
  totalActualWater: 'Gesamte tatsächliche Wassermenge',
  timeDischarged: 'Entladezeit',
  ingredient: 'Zutat',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Freie Feuchte',
  cydsTarg: 'CYDS Ziel',
  target: 'Ziel',
  actual: 'Tatsächlich',
  freWat: 'Freies Wasser',
  trim: 'Trimmen',
  batchInformation: 'Batch Informationen',
  numberOfBatches: 'Anzahl der Chargen',
  totalWater: 'Gesamtwasser',
  designWater: 'Designwasser',
  toAdd: 'Hinzufügen',
  designWC: 'Design W/C',
  waterCement: 'Wasser / Zement',
  waterInTruck: 'Wasser im LKW',
  adjustWater: 'Wasser anpassen',
  trimWater: 'Wasser trimmen',
  plantWeather: 'Pflanzenwetter',
  rh: 'RH',
  observationTime: 'Beobachtungszeit',
  address: 'Adresse',
  plant: 'Werk',
  sp: 'S.P. #',
  beginTime: 'Beginnzeit',
  mixDesc: 'Mischbeschreibung',
  qtyOder: 'Menge Bestellung',
  poTag: 'PO #',
  bridge: 'Brücke #',
  volume: 'Volumen',
  truckTag: 'LKW #',
  qtyDelivered: 'Menge Geliefert',
  qtyOrder: 'Menge Bestellung',
  job: 'Job #',
  batchRecord: 'Batch Aufnahme',
  mixID: 'Misch ID',
  loads: 'Ladungen',
  isDot: 'Ist DOT',
  tolStatus: 'TOL Status',
};
